import {
  GridIcon,
  AppWindowIcon,
  MapPinIcon,
  ColumnsIcon,
  MessageSquareDashedIcon,
  ListIcon,
  CalendarDaysIcon,
  Pencil as EditIcon,
  BadgeInfo as DocumentIcon,
  TableIcon,
  ScrollTextIcon,
  Layers3Icon,
  Table2Icon
} from "lucide-react";
import { ViewOption } from "utils/constants";

export const ViewsLabel = {
  [ViewOption.GRID]: "Grid",
  [ViewOption.CARD]: "Card",
  [ViewOption.MAP]: "Map",
  [ViewOption.BOARD]: "Board",
  [ViewOption.NOTE]: "Note",
  [ViewOption.LIST]: "List",
  [ViewOption.CALENDAR]: "Calendar",
  [ViewOption.FORM]: "Form",
  [ViewOption.DETAIL_MAIN]: "Detail",
  [ViewOption.MATRIX]: "Matrix",
  [ViewOption.ACTIVITY]: "Activity",
  [ViewOption.MULTI_PAGES]: "MultiPages",
  [ViewOption.TABLE_LITE]: "Table Lite"
};

export const ViewsIcon = {
  [ViewOption.GRID]: GridIcon,
  [ViewOption.CARD]: AppWindowIcon,
  [ViewOption.MAP]: MapPinIcon,
  [ViewOption.BOARD]: ColumnsIcon,
  [ViewOption.NOTE]: MessageSquareDashedIcon,
  [ViewOption.LIST]: ListIcon,
  [ViewOption.CALENDAR]: CalendarDaysIcon,
  [ViewOption.FORM]: EditIcon,
  [ViewOption.DETAIL_MAIN]: DocumentIcon,
  [ViewOption.MATRIX]: TableIcon,
  [ViewOption.ACTIVITY]: ScrollTextIcon,
  [ViewOption.MULTI_PAGES]: Layers3Icon,
  [ViewOption.TABLE_LITE]: Table2Icon
};
export const AvailableViews = [
  ViewOption.GRID,
  ViewOption.CARD,
  ViewOption.MAP,
  ViewOption.BOARD,
  ViewOption.NOTE,
  ViewOption.LIST,
  ViewOption.CALENDAR,
  ViewOption.MATRIX,
  ViewOption.ACTIVITY,
  ViewOption.MULTI_PAGES
];

export const viewOptions = AvailableViews.map((opt) => ({
  value: opt,
  title: ViewsLabel[opt]
}));
