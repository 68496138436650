import flatMap from "lodash/flatMap";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import LexoRank from "lib/LexoRank";
import { MenuItem } from "types/baTypes";
import { RecordItem } from "types/common";
import { CellType, emptySortOrder, LookupTypes } from "utils/constants";

export const menuItemsTableColumns = [
  {
    id: "name",
    name: "name",
    hasFilter: false,
    header: "Name",
    type: CellType.TEXT,
    hasBulkEdit: false,
    sortOrder: emptySortOrder
  },
  {
    id: "page",
    name: "page",
    hasFilter: false,
    header: "Page",
    type: CellType.TEXT,
    hasBulkEdit: false,
    sortOrder: emptySortOrder,
    isLookup: true,
    lookupPath: {
      "0": {
        lookupTableName: "pages",
        lookupColumns: ["title"],
        lookupType: LookupTypes.FOREIGN,
        lookupForeignKey: "ui_page_id"
      }
    }
  },
  {
    id: "detail_tab",
    name: "tab",
    hasFilter: false,
    header: "Detail Tab",
    type: CellType.TEXT,
    hasBulkEdit: false,
    sortOrder: emptySortOrder
  },
  {
    id: "url",
    name: "url",
    hasFilter: false,
    header: "Url",
    type: CellType.URL,
    hasBulkEdit: false,
    sortOrder: emptySortOrder
  },
  {
    id: "editBtn",
    name: "editBtn",
    hasFilter: false,
    header: "Edit",
    type: CellType.BUTTON,
    cellConfig: {
      label: "Edit"
    },
    hasBulkEdit: false,
    sortOrder: emptySortOrder,
    disableSorting: true
  },

  {
    id: "sortBtn",
    name: "sortBtn",
    hasFilter: false,
    header: "Sort (A-Z)",
    type: CellType.BUTTON,
    cellConfig: {
      label: "Sort"
    },
    hasBulkEdit: false,
    sortOrder: emptySortOrder,
    disableSorting: true
  },
  {
    id: "actionsBtn",
    name: "actionsBtn",
    hasFilter: false,
    header: "Actions",
    type: CellType.BUTTON,
    cellConfig: {
      label: "Manage actions"
    },
    hasBulkEdit: false,
    sortOrder: emptySortOrder,
    disableSorting: true
  },
  {
    id: "deleteBtn",
    name: "deleteBtn",
    hasFilter: false,
    header: "Delete",
    type: CellType.BUTTON,
    cellConfig: {
      label: "Delete"
    },
    hasBulkEdit: false,
    sortOrder: emptySortOrder,
    disableSorting: true
  }
];

export const getLevelMenu = (item: MenuItem, items: MenuItem[]): number => {
  if (!item.parent_id) return 0;

  const parent = items.find((i) => i.id === item.parent_id);

  if (!parent?.parent_id) return 1;

  return getLevelMenu(parent, items) + 1;
};

export const getMenuItemNameWithParentName = (menuItems: MenuItem[], currentMenuItem: MenuItem) => {
  let name = currentMenuItem.name;
  let parent_id = currentMenuItem.parent_id;
  while (!!parent_id) {
    const parentItem = menuItems.find((menuItem) => menuItem.id === parent_id);
    if (parentItem) {
      name = `${parentItem.name} > ${name}`;
    }
    parent_id = parentItem?.parent_id ?? null;
  }
  return name;
};

export const getUpdatedSortOrderForMenuItem = (menuItems: MenuItem[], item: MenuItem) => {
  let sort_order: string | undefined;
  const groupedMenuItems = groupBy(menuItems, "parent_id");
  if (item.is_folder || !item.parent_id || item.is_divider) {
    const items = sortBy(groupedMenuItems["null"] || [], "sort_order");
    if (items.length) {
      sort_order = LexoRank.from(items[items.length - 1].sort_order)
        .increment()
        .toString();
    } else {
      sort_order = LexoRank.getNewRank([], 0, 0)?.toString();
    }
  } else {
    const items = sortBy(groupedMenuItems[item.parent_id] || [], "sort_order");
    if (items.length) {
      sort_order = LexoRank.from(items[items.length - 1].sort_order)
        .increment()
        .toString();
    } else {
      sort_order = LexoRank.getNewRank([], 0, 0)?.toString();
    }
  }
  return sort_order;
};

export const alphabeticalSortMenuItemChildrens = (menuItems: RecordItem[], key = "name") => {
  const newRanks = LexoRank.getNewRanks([], menuItems.length, 0, 0);
  const nonDividerItems = menuItems.filter((item) => !item.is_divider);

  // Sort the non-divider items alphabetically
  const sortedNonDividerItems = sortBy([...nonDividerItems], key);

  // Combine sorted non-divider items and divider items
  const sortedMenuItems: RecordItem[] = flatMap(menuItems, (item) => {
    if (item.is_divider) {
      return [item];
    } else {
      const sortedItem = sortedNonDividerItems.shift();
      return sortedItem ? [sortedItem] : [];
    }
  });

  sortedMenuItems.forEach((item, index) => {
    const newItem = { ...item };
    newItem.sort_order = newRanks[index]?.toString();
    if (newItem.children.length) {
      newItem.children = alphabeticalSortMenuItemChildrens(newItem.children);
    }
    sortedMenuItems[index] = newItem;
  });

  return sortedMenuItems;
};

export const EDIT_PAGE_ACTION_ID = 9;
export const EDIT_MENU_ITEM_ACTION_ID = 77;
