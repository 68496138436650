import { useQuery } from "@tanstack/react-query";

import useSupabaseBrowser from "utils/supabaseBrowserClient";
import { getSearchTableById } from "lib/adminApi";
import { QueryHookOptions } from "types/common";
import useCurrentUser from "./useCurrentUser";

const useSearchTableById = (id?: string, options?: QueryHookOptions, isAdminPage?: boolean) => {
  const supabaseClient = useSupabaseBrowser();
  const currentUser = useCurrentUser();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["algolia_search_tables", id, currentUser?.org_id],
    queryFn: () => getSearchTableById(supabaseClient, id, currentUser?.org_id, isAdminPage),
    enabled: !!id,
    refetchOnMount: true,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    ...options
  });

  return {
    data: data?.data,
    isLoading,
    refetch
  };
};

export default useSearchTableById;
