import { KEY_TO_BLOCK_IN_SEARCH_SELECT } from "utils/constants";

type DropdownSearchUiProps = {
  query: string;
  ref?: (node: HTMLInputElement) => void;
  onSearch: (query: string) => void;
};

const DropdownSearchUi = ({ query, ref, onSearch }: DropdownSearchUiProps) => {
  return (
    <input
      type="text"
      className="bg-background text-primary mb-2 w-full rounded-lg border-none p-3 text-xs focus:ring-0 focus:blur-0 "
      placeholder="Search.."
      value={query}
      onChange={(e) => onSearch(e.target.value)}
      onKeyDown={(e) => {
        if (!KEY_TO_BLOCK_IN_SEARCH_SELECT.includes(e.code)) {
          e.stopPropagation();
        }
      }}
      ref={ref}
    />
  );
};

export default DropdownSearchUi;
