import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import clsx from "clsx";
import Popper from "@mui/material/Popper";

import { LucideIcon } from "lucide-react";
import Button from "components/Button";
import { SelectOptions } from "types/common";
import { ButtonAppearance } from "utils/constants";
import DropdownSearchUi from "components/DropdownSearchUi";

type DropdownMenuProps = {
  label: string;
  icon?: LucideIcon | ((props: any) => JSX.Element) | null;
  options: SelectOptions;
  onSelect: (colId: string | number) => void;
  isMultiple?: boolean;
  classNameButton?: string;
};

const DropdownMenu = ({
  icon,
  label,
  options,
  onSelect,
  isMultiple = false,
  classNameButton = ""
}: DropdownMenuProps) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false); // Works only for multiple select
  const [targetElement, setTargetElement] = useState<null | HTMLElement>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  const optionsFiltered = useMemo(
    () => options.filter((option) => option.title.toLowerCase().includes(query.toLowerCase())),
    [options, query]
  );

  const searchRef = (node: HTMLInputElement) => {
    if (node !== null) {
      node?.focus();
    }
  };

  useEffect(() => {
    if (!isMultiple) return;

    const handleClickOutside = (event: TouchEvent | MouseEvent) => {
      const wasInContainer = containerRef.current && containerRef.current.contains(event.target as Node);
      if (!wasInContainer) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMultiple]);

  return (
    <Menu>
      {({ open }) => {
        const id = open ? "popper" : undefined;

        return (
          <>
            <Menu.Button as={Fragment}>
              <Button
                label={label}
                appearance={ButtonAppearance.SECONDARY}
                icon={icon}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  isMultiple ? setIsOpen(true) : null;
                  setTargetElement(e.currentTarget);
                }}
                className={classNameButton}
              />
            </Menu.Button>
            <Popper
              open={isMultiple ? isOpen : open}
              id={id}
              anchorEl={targetElement}
              disablePortal={false}
              className="z-50"
            >
              <Transition
                show={isMultiple ? isOpen : open}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100 relative z-10"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Menu.Items
                  className="bg-dropdown text-primary border-dropdown z-10 mt-1 min-w-[200px] origin-top-right rounded-lg border p-1 shadow-lg focus:outline-none"
                  static={isMultiple}
                  ref={containerRef}
                >
                  {(optionsFiltered?.length > 6 || !!query) && (
                    <DropdownSearchUi query={query} onSearch={setQuery} ref={searchRef} />
                  )}
                  <main className={clsx("max-h-[250px] space-y-1 overflow-y-auto pb-1")}>
                    {optionsFiltered.map((option) => (
                      <Menu.Item key={option.value as string}>
                        {({ active }) => (
                          <button
                            className={clsx(
                              "relative flex w-full cursor-pointer rounded-md  px-2.5 py-2 text-left  text-sm font-medium hover:bg-gray-100 active:bg-neutral-200 dark:hover:bg-gray-700 dark:active:bg-neutral-dark-200",
                              active && "bg-neutral-100 dark:bg-neutral-dark-100"
                            )}
                            onClick={() => onSelect(option.value as string)}
                          >
                            {option.title}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                    {!optionsFiltered?.length && (
                      <div className="text-primary px-3 py-2 text-sm font-medium">No results</div>
                    )}
                  </main>
                </Menu.Items>
              </Transition>
            </Popper>
          </>
        );
      }}
    </Menu>
  );
};

export default DropdownMenu;
