import React, { useEffect, useMemo, useRef, useState } from "react";
import { Transition } from "@headlessui/react";
import { useMediaQuery } from "usehooks-ts";
import { FilterIcon, LayersIcon, ArrowDownUp as SortIcon, PlusIcon, XIcon, LucideIcon } from "lucide-react";

import Popper from "@mui/material/Popper";
import clsx from "clsx";

import { DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import type { Active } from "@dnd-kit/core";
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { v4 } from "uuid";

import { TableColumnType } from "types/baTypes";
import { ButtonAppearance, ControlType } from "utils/constants";
import Button from "components/Button";
import DropdownMenu from "components/DropdownMenu";
import { TableColumnTypeAndFilters, getCountValidFilters } from "components/Bar/Filters/utils";
import Tooltip from "components/Tooltip";
import IconButton from "components/IconButton";
import { IconButtonColor } from "components/IconButton/utils";
import ControlDropdownItem from "./ControlDropdownItem";

type ConfigType = {
  [key in ControlType]: {
    label: string;
    icon: LucideIcon;
    title: string;
    multi: boolean;
    addLabel: string;
    rowClassName: string;
    empty: string;
    showCount?: boolean;
  };
};

const configType: ConfigType = {
  [ControlType.FILTER]: {
    label: "Filter",
    title: "Filters",
    icon: FilterIcon,
    multi: true,
    addLabel: "Add Filter",
    rowClassName: "flex flex-col flex-nowrap items-center gap-2 w-full !pt-5 first:!pt-0 lg:!pt-0",
    empty: "There are no filters applied.",
    showCount: true
  },
  [ControlType.GROUP]: {
    label: "Group",
    title: "Groups",
    icon: LayersIcon,
    multi: false,
    addLabel: "Add Group",
    rowClassName: "grid grid-cols-[1fr_129px_44px] gap-2",
    empty: "There are no groups applied.",
    showCount: false
  },
  [ControlType.SORT]: {
    label: "Sort",
    title: "Sorts",
    icon: SortIcon,
    multi: true,
    addLabel: "Add Sort",
    rowClassName: "grid grid-cols-[1fr_200px_auto] gap-2",
    empty: "There are no sorts applied.",
    showCount: true
  }
};

type ControlDropdownProps = {
  type: ControlType;
  columns: TableColumnType[];
  renderItem: (item: TableColumnTypeAndFilters, filterIndex: number) => React.ReactNode;
  rows: any[];
  onUpdateRow: (rows: any[]) => void;
  customToggleHandler?: (colId: string) => void;
  customChangeColOrderHandler?: (newColId: string, prevColId: string) => void;
  customAddEmptyRow?: () => void;
  open?: boolean;
  isFilters?: boolean;
  onRemoveRow?: (id: string) => void;
};

const ControlDropdown = ({
  type,
  columns,
  renderItem,
  rows,
  onUpdateRow,
  customToggleHandler,
  customChangeColOrderHandler,
  customAddEmptyRow,
  open = false,
  isFilters,
  onRemoveRow
}: ControlDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [, setActive] = useState<Active | null>(null);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const isDesktop = useMediaQuery("(min-width: 1024px)");

  const count = useMemo(() => {
    if (!configType[type].showCount) return undefined;
    return type === ControlType.FILTER ? getCountValidFilters(rows) : rows.length ?? undefined;
  }, [type, rows]);

  const columnOptions = useMemo(() => {
    if (type === ControlType.FILTER) {
      return columns.map((column) => ({
        value: column.id,
        title: column.header || column.name,
        column
      }));
    }
    return columns
      .filter((item) => !rows.map((col) => col.id).includes(item.id))
      .map((column) => ({
        value: column.id,
        title: column.header || column.name,
        column
      }));
  }, [columns, rows, type]);

  const handleToggle = (colId: string) => {
    if (customToggleHandler) {
      return customToggleHandler(colId);
    }

    const row = rows.find((row) => row.id === colId);

    if (row && type !== ControlType.FILTER) {
      onUpdateRow(rows.filter((col) => col.id !== colId));
    } else {
      const column = columns.find((col) => col.id === colId);

      if (!column) return;
      onUpdateRow([...rows, column]);
    }
  };

  const handleRemove = (colId: string) => {
    if (onRemoveRow) {
      onRemoveRow(colId);
      return;
    }
    handleToggle(colId);
  };

  const handleChangeColumn = (newColId: string, prevColId: string) => {
    if (customChangeColOrderHandler) {
      return customChangeColOrderHandler(newColId, prevColId);
    }

    const column = columns.find((col) => col.id === newColId);
    if (!column) return;
    const newSelectedColumns = rows.map((col) => (col.id === prevColId ? column : col));
    onUpdateRow(newSelectedColumns);
  };

  const handleAddEmptyRow = () => {
    if (customAddEmptyRow) {
      return customAddEmptyRow();
    }
    onUpdateRow([...rows, { id: v4() }]);
  };

  // This works when we want open externally
  useEffect(() => {
    if (open) {
      setIsOpen(true);
    }
  }, [open]);

  useEffect(() => {
    const handleClickOutside = (event: TouchEvent | MouseEvent) => {
      if (!containerRef.current) return;

      const eventTarget = event.target as Node;
      const wasInContainer = containerRef.current?.contains(eventTarget);
      const wasInGlobal = document.getElementById("global")?.contains(eventTarget);
      const wasInExpandedView = document.getElementById("expanded-view")?.contains(eventTarget);
      const wasInSidebar = document.getElementById("sidebar")?.contains(eventTarget);
      const wasInButton = buttonRef.current?.contains(eventTarget);

      if (!wasInContainer && (wasInGlobal || wasInExpandedView || wasInSidebar) && !wasInButton) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  const id = open ? "popper" : undefined;

  return (
    <div className="relative">
      <Tooltip title={configType[type].label}>
        <IconButton
          className="h-9 w-auto px-3 py-2 shadow-50 hover:shadow-none  active:shadow-none"
          classNameIcon="shrink-0"
          icon={configType[type].icon}
          color={IconButtonColor.SECONDARY}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            setIsOpen((prev) => {
              return !prev;
            });
          }}
          ref={buttonRef}
          count={count}
        />
      </Tooltip>

      <Popper
        open={isOpen}
        id={id}
        anchorEl={buttonRef.current}
        disablePortal={false}
        className={clsx("z-50", !isDesktop && "!fixed !inset-0 !transform-none")}
        placement="bottom-start"
      >
        <div className="relative z-50" ref={containerRef}>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="lg:transform lg:scale-100 lg:opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="lg:transform lg:scale-100 lg:opacity-100"
            leaveTo="transform scale-95 opacity-0"
            show={isOpen}
          >
            <div className="border-separator bg-background fixed inset-0 z-50 h-screen w-screen border shadow-lg focus:outline-none lg:relative lg:mt-2 lg:h-auto lg:w-[600px] lg:rounded-lg lg:shadow-100 dark:lg:shadow-dark100">
              <header className="border-separator grid grid-cols-3 items-center border-b  px-3 py-2.5  lg:hidden">
                <button onClick={() => setIsOpen(false)}>
                  <XIcon className="h-5 w-5" />
                </button>
                <span className="text-center font-semibold">{configType[type].title}</span>

                {type === ControlType.FILTER ? (
                  <DropdownMenu
                    label="Add"
                    icon={PlusIcon}
                    options={columnOptions}
                    onSelect={(value) => handleToggle(value as string)}
                  />
                ) : (
                  <Button
                    label="Add"
                    appearance={ButtonAppearance.SECONDARY}
                    icon={PlusIcon}
                    className="w-20 justify-self-end"
                    onClick={handleAddEmptyRow}
                    disabled={columnOptions.length === 0 || columnOptions.length === rows.length}
                  />
                )}
              </header>
              <div className="h-full">
                {rows?.length > 0 ? (
                  <DndContext
                    sensors={sensors}
                    onDragStart={({ active }) => {
                      setActive(active);
                    }}
                    onDragEnd={({ active, over }) => {
                      if (over && active.id !== over?.id) {
                        const activeIndex = rows.findIndex(({ id }) => id === active.id);
                        const overIndex = rows.findIndex(({ id }) => id === over.id);

                        onUpdateRow(arrayMove(rows, activeIndex, overIndex));
                      }
                      setActive(null);
                    }}
                    onDragCancel={() => {
                      setActive(null);
                    }}
                  >
                    <SortableContext items={rows}>
                      <ul
                        className={clsx(
                          "SortableList border-separator flex flex-col rounded-lg px-5 py-2.5 max-lg:h-full max-lg:overflow-y-auto max-lg:pb-20 lg:gap-y-2 lg:py-5",
                          type === ControlType.FILTER
                            ? "gap-y-5 divide-y divide-black/[7%] dark:divide-white/[7%] lg:divide-y-0"
                            : "gap-y-6"
                        )}
                        role="application"
                      >
                        {rows.map((column, index) => (
                          <ControlDropdownItem
                            key={column.id}
                            id={column.id}
                            title={column.header || column.name}
                            columnOptions={columnOptions}
                            className={configType[type].rowClassName}
                            onChangeColumn={handleChangeColumn}
                            onRemove={handleRemove}
                            renderItem={renderItem}
                            showDragAndDrop={rows?.length > 1}
                            isFilters={isFilters}
                            filterIndex={index}
                            item={column}
                          />
                        ))}
                      </ul>
                    </SortableContext>
                  </DndContext>
                ) : (
                  <div className="p-5">
                    <p className="text-center text-sm text-base-disabled dark:text-base-dark-disabled lg:text-left">
                      {configType[type].empty}
                    </p>
                  </div>
                )}
                {columnOptions.length > 0 && (
                  <div className="border-separator hidden border-t px-5 py-2 lg:block">
                    <DropdownMenu
                      label={configType[type].addLabel}
                      icon={PlusIcon}
                      options={columnOptions}
                      onSelect={(value) => handleToggle(value as string)}
                    />
                  </div>
                )}
              </div>
            </div>
          </Transition>
        </div>
      </Popper>
    </div>
  );
};

export default ControlDropdown;
