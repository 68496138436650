import React, { Fragment, useState } from "react";
import clsx from "clsx";
import { Listbox, Transition } from "@headlessui/react";
import { CheckCircle2 as CheckIcon, ChevronDownIcon, XIcon } from "lucide-react";

import Popper from "@mui/material/Popper";

import { SelectOption, SelectOptions, SelectValue } from "types/common";
import DropdownSearchUi from "components/DropdownSearchUi";
import IconButton from "components/IconButton";
import { IconButtonSize } from "components/IconButton/utils";
import Icon from "components/Icon";
import GenericSelectItem from "./GenericSelectItem";

type SelectCellGroupProps = {
  options: SelectOptions;
  value?: SelectOption | SelectOptions | null;
  isMultiple?: boolean;
  showSearch?: boolean;
  onSelect: (values: SelectValue) => void;
  disabled?: boolean;
  searchRef?: (node: HTMLInputElement) => void;
  query?: string;
  onSearch: (query: string) => void;
  classNameOptions?: string;
  position?: "left" | "right";
};

export const GenericSelect = ({
  options = [],
  value,
  isMultiple,
  showSearch = false,
  onSelect,
  disabled = false,
  searchRef,
  query = "",
  onSearch,
  classNameOptions = "",
  position = "left"
}: SelectCellGroupProps) => {
  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(null);

  return (
    <Listbox
      as="div"
      data-testid="SelectCellGroup"
      className="relative flex w-full overflow-hidden"
      value={value}
      onChange={onSelect}
      multiple={isMultiple}
      disabled={disabled}
      by="value"
    >
      {({ open }) => {
        const id = open ? "popper" : undefined;

        return (
          <>
            <Listbox.Button as={Fragment}>
              <div
                className={clsx(
                  "flex w-full cursor-pointer items-center whitespace-nowrap rounded-lg border border-transparent bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-dark-100 dark:hover:bg-neutral-dark-200",
                  "border-separator min-h-[56px] border px-3 py-[11px] text-sm ",
                  open && "ring-2 ring-primary-700 dark:ring-primary-dark-700"
                )}
                ref={setTargetElement}
              >
                {value && !isMultiple && <div className="flex-1">{(value as SelectOption).title}</div>}
                {Array.isArray(value) && !!value?.length && isMultiple && (
                  <div className="flex flex-wrap gap-1">
                    {(value as SelectOptions)?.map((item) => (
                      <GenericSelectItem key={item.value} value={item.title} isMultiple />
                    ))}
                  </div>
                )}
                {((!value && !isMultiple) || (!(value as SelectOptions)?.length && isMultiple)) && (
                  <span className="text-base-disabled">Select..</span>
                )}
                {!isMultiple && open && value && (
                  <IconButton
                    className="absolute right-6"
                    icon={XIcon}
                    color="transparent"
                    onClick={(e) => {
                      e.stopPropagation();
                      onSelect(null);
                    }}
                    size={IconButtonSize.XS}
                    type="button"
                  />
                )}
                <ChevronDownIcon className="absolute right-2 h-4 w-4" />
              </div>
            </Listbox.Button>
            <Popper
              open={open}
              id={id}
              anchorEl={targetElement}
              disablePortal={false}
              className="z-50"
              placement="bottom-start"
            >
              <div className="my-1">
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    className={clsx(
                      "bg-dropdown border-dropdown h-full min-w-[300px] origin-top-right rounded-lg border p-1 shadow-100  focus:outline-none dark:shadow-dark100",
                      position === "left" && "left-0",
                      position === "right" && "right-0",
                      classNameOptions
                    )}
                    static
                  >
                    {showSearch && (options?.length > 6 || !!query) && (
                      <DropdownSearchUi query={query} onSearch={onSearch} ref={searchRef} />
                    )}

                    <main className={clsx("max-h-[250px] space-y-1 overflow-y-auto pb-1")}>
                      {options?.map((option, optionIdx: number) => (
                        <Listbox.Option
                          key={optionIdx}
                          className={({ active }) =>
                            clsx(
                              "relative flex w-full cursor-pointer items-center px-3 py-1 font-medium hover:bg-neutral-100 active:bg-neutral-200 dark:hover:bg-neutral-dark-100 dark:active:bg-neutral-dark-200",
                              active && "bg-neutral-100 dark:bg-neutral-dark-100"
                            )
                          }
                          value={option}
                          as="div"
                        >
                          {({ selected }) => (
                            <>
                              {option.icon && <Icon className="mr-2 h-4 w-4" name={option.icon} />}
                              <span className="text-sm font-medium">{option.title}</span>
                              {selected && (
                                <CheckIcon className="absolute right-3 top-1 h-5 w-5 text-primary-700 dark:text-primary-dark-700" />
                              )}
                            </>
                          )}
                        </Listbox.Option>
                      ))}

                      {!options?.length && (
                        <div className="p-3 text-sm font-medium text-base-disabled dark:text-base-dark-disabled">
                          No results
                        </div>
                      )}
                    </main>
                  </Listbox.Options>
                </Transition>
              </div>
            </Popper>
          </>
        );
      }}
    </Listbox>
  );
};

export default GenericSelect;
