import { useRef } from "react";
import clsx from "clsx";
import { useHover } from "usehooks-ts";
import { XIcon } from "lucide-react";

export type GenericCellItemProps = {
  value: string;
  isMultiple?: boolean;
  onRemoveClick?: () => void;
  className?: string;
};

const GenericCellItem = ({ value, isMultiple, onRemoveClick, className = "" }: GenericCellItemProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  if (isMultiple) {
    return (
      <div
        className={clsx(
          "relative inline-flex flex-nowrap items-center gap-x-1 overflow-hidden rounded-full bg-neutral-200 px-2 py-1 dark:bg-neutral-dark-200",
          className
        )}
        ref={hoverRef}
      >
        <div className="relative flex">
          <div
            className={clsx(
              "overflow-hidden overflow-ellipsis  whitespace-nowrap text-sm font-medium",
              "max-w-[200px] opacity-0"
            )}
          >
            {value}
          </div>
          <div className="absolute inset-0 flex overflow-hidden">
            <div className={"overflow-hidden overflow-ellipsis  whitespace-nowrap text-sm font-medium"}>{value}</div>
            {onRemoveClick && (
              <button
                className={clsx("", isHovered ? "block" : "hidden")}
                onClick={(e) => {
                  e.stopPropagation();
                  onRemoveClick?.();
                }}
              >
                <XIcon className="h-4 w-4" />
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        "overflow-hidden overflow-ellipsis whitespace-nowrap text-sm font-medium",
        "max-w-[260px] overflow-hidden overflow-ellipsis py-2",
        className
      )}
    >
      {value}
    </div>
  );
};

export default GenericCellItem;
