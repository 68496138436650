import clsx from "clsx";
import { Trash2 as DeleteIcon, MoreHorizontalIcon } from "lucide-react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import type { CSSProperties } from "react";
import React from "react";
import { TableColumnTypeAndFilters } from "components/Bar/Filters/utils";
import GenericSelect from "components/GenericSelect";
import IconButton from "components/IconButton";
import { IconButtonColor } from "components/IconButton/utils";
import { TableColumnType } from "types/baTypes";
import { SelectOption, SelectOptions } from "types/common";

type ControlDropdownItem = {
  id: string;
  title: string;
  className: string;
  columnOptions: SelectOptions;
  onChangeColumn: (newColId: string, oldColId: string) => void;
  onRemove: (id: string) => void;
  renderItem: (item: TableColumnTypeAndFilters | TableColumnType, filterIndex: number) => React.ReactNode;
  showDragAndDrop?: boolean;
  isFilters?: boolean;
  filterIndex?: number;
  item: TableColumnTypeAndFilters;
};

const ControlDropdownItem = ({
  id,
  title,
  className,
  columnOptions,
  onChangeColumn,
  onRemove,
  renderItem,
  showDragAndDrop = false,
  isFilters,
  filterIndex,
  item
}: ControlDropdownItem) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
    id
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div
      key={id}
      className={clsx("justify-between", !isFilters ? "grid items-center " : "", className)}
      ref={setNodeRef}
      style={style}
    >
      {!isFilters ? (
        <GenericSelect
          options={columnOptions}
          onSelect={(newColId) => onChangeColumn((newColId as SelectOption).value as string, id)}
          initialValues={{ value: id, title: title }}
          showSearch={true}
        />
      ) : null}
      {renderItem(item, filterIndex || 0)}
      {!isFilters ? (
        <div>
          <IconButton icon={DeleteIcon} onClick={() => onRemove(id)} color={IconButtonColor.TRANSPARENT} />
          {showDragAndDrop && (
            <IconButton
              className="DragHandle"
              {...attributes}
              {...listeners}
              icon={MoreHorizontalIcon}
              color={IconButtonColor.TRANSPARENT}
              onClick={() => null}
              ref={setActivatorNodeRef}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ControlDropdownItem;
