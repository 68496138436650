import { MultipleQueriesQuery, SearchResponse, SearchForFacetValuesResponse } from "@algolia/client-search";
import { SearchClient, SearchIndex } from "algoliasearch";
import omit from "lodash/omit";
import { getMenuItemNameWithParentName } from "app/(authRoutes)/(global)/admin/menus/[menuId]/utils";
import { AlgoliaSearchTable, MasterIndexLinkedConfigWithJoinInfo, MenuItem, TableColumnType } from "types/baTypes";
import { ActiveSearchContextState, NavigationItem, RecordItem } from "types/common";
import { ALGOLIA_MASTER_INDEX, ALGOLIA_MENU_ITEMS_INDEX, CellType, RELATION_TYPES } from "utils/constants";
import { getGenericCellValuesFromRecord } from "utils/dataUtils";

export type AlgoliaSearchResult = {
  index?: string;
  objectID: string;
  title?: string;
  subtitleColumns?: Partial<TableColumnType>[];
  uniqueAttributes?: RecordItem;
} & RecordItem;

type AlgoliaParams = { filters?: string; facetFilters?: Array<string | string[]>; hitsPerPage?: number; page?: number };

type AlgoliaSearchCallBacks = {
  onSuccess?: (results: AlgoliaSearchResult[], totalHits?: number, currentPage?: number) => void;
  onError?: (error: any) => void;
};

export type AlgoliaMultiSearchResults = {
  hits: AlgoliaSearchResult[];
  nbHits: number;
  facets?: Record<string, Record<string, number>>;
  index: string;
};

type AlgoliaMultiSearchCallbacks = {
  onSuccess?: (results: AlgoliaMultiSearchResults[], totalHits?: number, currentPage?: number) => void;
  onError?: (error: any) => void;
};

export const generateQueries = ({
  search,
  indices = [],
  userType,
  isAdmin = false,
  activeFacet,
  searchParamValues,
  searchContexts,
  allMasterConfigLinkedConfig,
  searchTableData,
  currentFacetInView,
  orgId
}: {
  search: string;
  indices: string[];
  userType?: string;
  searchParamValues?: AlgoliaParams;
  isAdmin?: boolean;
  activeFacet?: string;
  searchContexts?: ActiveSearchContextState[];
  allMasterConfigLinkedConfig?: MasterIndexLinkedConfigWithJoinInfo[];
  searchTableData?: AlgoliaSearchTable[];
  currentFacetInView?: string;
  orgId?: string;
}) => {
  const queries: MultipleQueriesQuery[] = [];
  const params: AlgoliaParams = {};
  if (searchParamValues?.filters) {
    params.filters = searchParamValues.filters;
  }

  if (searchParamValues?.facetFilters?.length) {
    params.facetFilters = [...searchParamValues.facetFilters];
  }

  if (activeFacet) {
    if (Array.isArray(params.facetFilters)) {
      params.facetFilters.push(`${activeFacet}:${search}`);
    } else {
      params.facetFilters = [`${activeFacet}:${search}`];
    }
  }

  if (orgId) {
    if (Array.isArray(params.facetFilters)) {
      params.facetFilters.push(`org_id:${orgId}`);
    } else {
      params.facetFilters = [`org_id:${orgId}`];
    }
  }

  if (searchParamValues?.page) {
    // Algolia page is zero based, but our internal pagination is 1 based
    params.page = searchParamValues.page - 1;
  }

  if (searchParamValues?.hitsPerPage) {
    params.hitsPerPage = searchParamValues.hitsPerPage;
  }
  const menuIndexAdditionalFilters: Array<string | string[]> = [[`user_type:${userType}`, "user_type:All"]];
  if (!isAdmin) {
    menuIndexAdditionalFilters.push("is_admin:false");
  }
  for (const index of indices) {
    const finalFacetFilters: { facetFilters: (string | string[])[] } = {
      facetFilters: []
    };
    // We add the context filters based on active search contexts using all master config linked config
    if (searchContexts?.length && index === ALGOLIA_MASTER_INDEX && allMasterConfigLinkedConfig?.length) {
      const searchContextsLength = searchContexts.length;
      searchContexts.forEach((context, index) => {
        const validLinkeConfigColumns = allMasterConfigLinkedConfig.filter(
          (config) =>
            (config.type === RELATION_TYPES.FOREIGN && config.tableName === context.tableName) ||
            (config.type === RELATION_TYPES.JOIN && config.joinKeyToTable === context.tableName)
        );
        if (validLinkeConfigColumns?.length && context.recordId) {
          if (validLinkeConfigColumns?.length > 1) {
            const orFilter: string[] = [];
            // We need to add as OR filter
            validLinkeConfigColumns.forEach((col) => {
              orFilter.push(
                `${col.type === RELATION_TYPES.FOREIGN ? col.columnName : col.syncLabel || ""}:${context.recordId}`
              );
            });
            finalFacetFilters.facetFilters.push(orFilter);
          } else {
            validLinkeConfigColumns.forEach((config) => {
              finalFacetFilters.facetFilters.push(`${config.columnName}:${context.recordId}`);
            });
          }
        }
        if (context.isEntityType && context.title && index === searchContextsLength - 1) {
          // Check if there are context ahead that have record Ids, in that case we skip the entityType filter
          // entityType filter is only applied if it is the last value in the contexts
          finalFacetFilters.facetFilters.push(`entityType:${context.title}`);
        }
      });
    }

    if (index === ALGOLIA_MASTER_INDEX && currentFacetInView) {
      finalFacetFilters.facetFilters.push(`entityType:${currentFacetInView}`);
    }
    // Add orgId filter for master index
    if (orgId && index === ALGOLIA_MASTER_INDEX) {
      finalFacetFilters.facetFilters.push(`org_id:${orgId}`);
    }

    // For nested context non master index search we still add the context filters
    // We are assuming that the facet as been added
    if (searchContexts?.length && index !== ALGOLIA_MASTER_INDEX) {
      const indexSearchTable = searchTableData?.find((table) => table.indexName === index);
      if (indexSearchTable && indexSearchTable?.masterIndexConfig?.linkedConfig?.length) {
        searchContexts.forEach((context) => {
          // We check in the linked config in the master index to get all linked columns
          const linkedCols = indexSearchTable?.masterIndexConfig?.linkedConfig?.filter(
            (conf) => conf.tableName === context.tableName
          );
          if (linkedCols?.length && context?.recordId) {
            if (linkedCols?.length > 1) {
              const orFilter: string[] = [];
              // We need to add as OR filter
              linkedCols.forEach((col) => {
                orFilter.push(`${col.columnName}:${context.recordId}`);
              });
              finalFacetFilters.facetFilters.push(orFilter);
            } else {
              finalFacetFilters.facetFilters.push(`${linkedCols[0].columnName}:${context.recordId}`);
            }
          }
        });
      }
    }

    queries.push({
      indexName: index,
      query: activeFacet ? "" : search,
      params:
        index === ALGOLIA_MENU_ITEMS_INDEX
          ? {
              ...omit(params, ["hitsPerPage", "page"]),
              facetFilters: [...(params.facetFilters || []), ...menuIndexAdditionalFilters]
            }
          : index === ALGOLIA_MASTER_INDEX
            ? {
                ...omit(params, ["hitsPerPage", "page"]),
                ...finalFacetFilters,
                facets: ["entityType"]
              }
            : searchContexts?.length
              ? {
                  ...params,
                  ...finalFacetFilters
                }
              : params
    });
  }
  return queries;
};

const processSearchResults = (results: SearchResponse[] = []): AlgoliaMultiSearchResults[] => {
  return results.map((result: SearchResponse) => {
    const { hits = [], index, facets, nbHits } = result;
    const hitsWithIndex: AlgoliaSearchResult[] = hits.map((hit) => ({ ...hit, index }));
    return {
      hits: hitsWithIndex,
      nbHits,
      facets,
      index: index || ""
    };
  }, []);
};

export const runSearch = (
  algoliaSearchClient: SearchClient,
  queries: MultipleQueriesQuery[],
  callbacks?: AlgoliaMultiSearchCallbacks
) => {
  try {
    return algoliaSearchClient
      .multipleQueries(queries)
      .then(({ results = [] }: { results: (SearchForFacetValuesResponse | SearchResponse<unknown>)[] }) => {
        const searchResponses = results.filter((result): result is SearchResponse<unknown> => "hits" in result);
        const totalHits = (results as unknown as SearchResponse<unknown>[])?.[0]?.nbHits;
        const processedResults = processSearchResults(searchResponses);
        if (callbacks?.onSuccess) callbacks.onSuccess(processedResults, totalHits);
      })
      .catch((error) => {
        if (callbacks?.onError) callbacks.onError(error);
      });
  } catch (error: any) {
    console.error("runSearch function =>", error.message);
    if (callbacks?.onError) callbacks.onError(error);
    return [];
  }
};

// paginated single index search
export const runSingleIndexSearch = ({
  index,
  callbacks,
  query
}: {
  index: SearchIndex;
  query: MultipleQueriesQuery;
  callbacks?: AlgoliaSearchCallBacks;
}) => {
  // Don't perform empty search
  if (!index || (!query.query && !query.params?.facetFilters)) return;
  return index
    .search(query.query || "", {
      ...query.params
    })
    .then(({ hits = [], nbHits, page }: SearchResponse) => {
      if (callbacks?.onSuccess) callbacks.onSuccess(hits, nbHits, page);
    })
    .catch((error) => {
      if (callbacks?.onError) callbacks.onError(error);
      return [];
    });
};

// transform the menu items returned from useNavigationMenu hook to algolia results for nested page
export const transformNavigationMenuItemsToAlgoliaResults = (
  items: Array<NavigationItem>,
  searchTableTitle: string
): AlgoliaSearchResult[] => {
  return items
    .filter((menuItem) => !menuItem.is_divider && !menuItem.is_folder && !menuItem.is_section)
    .map((menuItem) => ({
      ...menuItem,
      title: `${
        menuItem.parent_id && items
          ? getMenuItemNameWithParentName(items as unknown as MenuItem[], menuItem as unknown as MenuItem)
          : menuItem.name
      }`,
      objectID: `${searchTableTitle}_${menuItem.id}`,
      index: ALGOLIA_MENU_ITEMS_INDEX,
      item_link: menuItem.href,
      resultIcon: menuItem.icon ?? "arrow-right",
      isNestedPageItem: true
    }));
};

export const isTargetNodeInSearchContainer = (target: HTMLElement | null) => {
  if (!target) return false;
  let isCommandMenuElement = false;
  const attributes = target.attributes;
  for (let i = 0; i < attributes.length; i++) {
    if (attributes[i].name.startsWith("cmdk")) {
      isCommandMenuElement = true;
      break;
    }
  }
  return isCommandMenuElement;
};

export const getSearchHitResult = (hits: RecordItem[], searchTableData?: AlgoliaSearchTable[]) => {
  const finalSearchResults: AlgoliaSearchResult[] = [];
  hits.forEach((hit) => {
    const searchTableInfo = searchTableData?.find(
      (tableData: AlgoliaSearchTable) => hit.entityIndex === tableData.indexName
    );
    const finalSearchTableTitle = searchTableInfo?.title;
    const finalSearchResult: AlgoliaSearchResult = {
      ...hit,
      objectID: hit.objectID,
      searchTableTitle: finalSearchTableTitle,
      resultPath: searchTableInfo?.linkedPage?.path,
      showInSidebar: searchTableInfo?.resultConfig?.showInSidebar,
      searchTableSortOrder: searchTableInfo?.sortOrder
    };
    const searchResultValue = hit.uniqueAttributes;
    if (searchResultValue) {
      if (searchTableInfo?.resultConfig?.resultColumn) {
        finalSearchResult.resultColumn = searchTableInfo.resultConfig.resultColumn;
        const value = getGenericCellValuesFromRecord({
          column: searchTableInfo.resultConfig.resultColumn as TableColumnType,
          recordData: searchResultValue,
          inGlobalSearch: true,
          columns: (searchTableInfo?.columns || []) as TableColumnType[]
        });

        finalSearchResult.resultColumnValue = Array.isArray(value) ? value[0] : value;
      } else {
        const resultTitleCol = searchTableInfo?.columns?.find((col) => col.type === CellType.TEXT);
        finalSearchResult.title = resultTitleCol
          ? searchResultValue[resultTitleCol.header || ""]
          : searchResultValue.id;
      }
      finalSearchResults.push(finalSearchResult);
    }
  });
  return finalSearchResults;
};
