import React, { useState, useMemo, useEffect } from "react";
import sortBy from "lodash/sortBy";

import { SelectOption, SelectOptions, SelectValue } from "types/common";
import GenericSelectUi from "./GenericSelectUi";

export type SelectCellGroupProps = {
  initialValues?: SelectOption | SelectOptions | null;
  options: SelectOptions;
  isMultiple?: boolean;
  showSearch?: boolean;
  onSelect: (values: SelectValue) => void;
  disabled?: boolean;
  classNameOptions?: string;
  isSorted?: boolean;
  position?: "left" | "right";
};

export const GenericSelect = ({
  options = [],
  initialValues,
  isMultiple,
  showSearch = false,
  isSorted = true,
  onSelect,
  disabled = false,
  classNameOptions = "",
  position = "left"
}: SelectCellGroupProps) => {
  const [selected, setSelected] = useState<SelectValue | undefined>(
    initialValues as SelectOptions | SelectOption | undefined
  );
  const [query, setQuery] = useState("");

  const optionsFiltered = useMemo(() => {
    const sortOptions = isSorted ? sortBy(options, (option) => option?.title?.toLowerCase()) : options;
    return sortOptions.filter((option) => option.title.toLowerCase().includes(query.toLowerCase()));
  }, [options, query, isSorted]);

  const searchRef = (node: HTMLInputElement) => {
    if (node !== null) {
      node?.focus();
    }
  };

  const handleSelect = (items: SelectOption | SelectOptions | null) => {
    setSelected(items);

    if (!isMultiple) {
      onSelect(items as SelectOption);
    } else {
      onSelect(items as SelectOptions);
    }
  };

  useEffect(() => {
    setSelected(initialValues);
  }, [initialValues]);

  return (
    <GenericSelectUi
      options={optionsFiltered}
      searchRef={searchRef}
      onSelect={handleSelect}
      query={query}
      onSearch={setQuery}
      showSearch={showSearch}
      disabled={disabled}
      value={selected}
      classNameOptions={classNameOptions}
      position={position}
      isMultiple={isMultiple}
    />
  );
};

export default GenericSelect;
